import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [2,4,5,6];

export const dictionary = {
		"/": [8],
		"/ai-spm/dashboard": [9,[2]],
		"/auth": [~10,[3]],
		"/auth/sign-in": [~11,[3]],
		"/auth/sign-up": [~12,[3]],
		"/auth/verify-email": [~13,[3]],
		"/auth/verify-session": [~14,[3]],
		"/auth/verify-session/[sessionId]": [~15,[3]],
		"/grc/actions": [18,[4]],
		"/grc/controls": [~19,[4]],
		"/grc/controls/[id]": [~20,[4]],
		"/grc/dashboard": [~21,[4]],
		"/grc/datasets": [~22,[4]],
		"/grc/(assignments)/documents": [~16,[4]],
		"/grc/frameworks": [~23,[4]],
		"/grc/frameworks/[id]": [~24,[4]],
		"/grc/models": [~25,[4]],
		"/grc/models/[id]": [26,[4,5]],
		"/grc/models/[id]/[ver]": [27,[4,5,6]],
		"/grc/models/[id]/[ver]/assessments": [28,[4,5,6]],
		"/grc/models/[id]/[ver]/reports": [29,[4,5,6]],
		"/grc/models/[id]/[ver]/reports/[rep]": [30,[4,5,6]],
		"/grc/org": [~31,[4,7]],
		"/grc/org/join-org": [~32,[4,7]],
		"/grc/org/switch-org": [~33,[4,7]],
		"/grc/questionnaires": [~34,[4]],
		"/grc/settings": [~35,[4]],
		"/grc/(assignments)/tests": [~17,[4]],
		"/grc/vendors": [~36,[4]],
		"/grc/vendors/[id]": [~37,[4]],
		"/public-vendor": [~38]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';